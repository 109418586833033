
export const WEBSERVER = "https://www.shopspot.de";
//export const WEBSERVER = "http://shoppilot.mooo.com:3000";

export const APISERVER = "https://api.shopspot.de/shop-api";
//export const APISERVER = "http://shoppilot.mooo.com:3001/shop-api";

export const TURNSTILE_SITEKEY = "0x4AAAAAAAT1zMfqTXFU1zkG";

export function numberToCurrency(n: number) {
    n =  n/100.0; 
    return n.toLocaleString('de-DE', {
      style: 'currency', 
      currency: 'EUR', 
      minimumFractionDigits: 2 
  });
}


export function imgpath(path: string) {
  if (process.env.NODE_ENV === 'development' || process.env.isDev) {
    //console.log('Website is running in development mode: ' + path);
  } else {
    //console.log('Website is running in production mode: ' + path);
  }
  path = path.replace('http://localhost:3001','https://api.shopspot.de');
  return path;
}

