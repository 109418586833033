const Footer = () => {
    return (
      <footer className="container-fluid pt-4">
        <small>
        <p> &copy; {new Date().getFullYear().toString()} by <a href="https://www.shoppilot.de" target="__new">IBO Internet Software</a></p>
        </small>
      </footer>
    );
  };
  
  export default Footer;
  