import Image from 'next/image';
import Link from 'next/link';
import {WEBSERVER} from "../services/globals";

const CONTACT_FORM  =  WEBSERVER + '/contact-form'

const Header = () => {
  return (
    <div>
      <div className="header-top d-flex flex-row justify-content-center bg-info">
        <div className="d-flex align-items-start"><a href={WEBSERVER} className="text-dark">Home<i className="bi-house-fill text-dark" title="home"></i></a></div>
        <li><i className="bi-telephone-fill" title="telephone"></i> 02166 9989530</li>
        <li>frei ab 49 EUR</li>
        <div className="d-flex"><a href={CONTACT_FORM} className="text-dark">Kontakt <i className="bi bi-info-square-fill text-dark"></i></a></div>
      </div>
  </div>
  );
};

export default Header;
