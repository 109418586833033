
import "bootstrap/dist/css/bootstrap.min.css";
import '../styles/styles.css';

import Layout from './layout';

import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloProvider, 
} from "@apollo/client";

import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { useEffect } from "react";

import {APISERVER} from "../services/globals";


const link = createHttpLink({
  uri: APISERVER,
  credentials: 'include'
});

const _client = new ApolloClient({
  cache: new InMemoryCache(),
  link
});


// _app.js
const MyApp = ({ Component, pageProps }) => {
  useEffect(() => {
    import("bootstrap/dist/js/bootstrap");
    import("bootstrap-icons/font/bootstrap-icons.css");
  }, []);
  return (     <Layout>
              <ApolloProvider client={_client}>
                  <Component {...pageProps} />
              </ApolloProvider>
              </Layout>
  )
}
export default MyApp




