import format from '../components/format.module.css';
import Header from '../components/header';
import Footer from '../components/footer';

export default function Layout
({children}) {

    return (<>
         <Header></Header>
         <main>{children}</main>
        <Footer></Footer>
    </>);
}
